import React from "react"

export default function ButtonGroup({
  isPtVisible,
  isPdVisible,
  isRhVisible,
  setPtVisible,
  setPdVisible,
  setRhVisible,
  isUSD,
  setUSD,
}) {
  return (
    <div className="flex justify-between px-1">
      <div>
        <span className="relative z-0 inline-flex rounded-md shadow-sm">
          <button
            onClick={() => {
              setPtVisible(true)
              setPdVisible(true)
              setRhVisible(true)
            }}
            type="button"
            className={`${
              isPtVisible & isPdVisible & isRhVisible
                ? "bg-gray-100"
                : "bg-white"
            } relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 
            hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500 sm:text-sm`}
          >
            Общие
          </button>
          <button
            onClick={() => {
              setPtVisible(true)
              setPdVisible(false)
              setRhVisible(false)
            }}
            type="button"
            className={`${
              isPtVisible & !isPdVisible & !isRhVisible
                ? "bg-gray-100"
                : "bg-white"
            } relative -ml-px inline-flex items-center border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 
            hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500 sm:text-sm`}
          >
            Pt
          </button>
          <button
            onClick={() => {
              setPtVisible(false)
              setPdVisible(true)
              setRhVisible(false)
            }}
            type="button"
            className={`${
              !isPtVisible & isPdVisible & !isRhVisible
                ? "bg-gray-100"
                : "bg-white"
            } relative -ml-px inline-flex items-center border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 
            hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500 sm:text-sm`}
          >
            Pd
          </button>
          <button
            onClick={() => {
              setPtVisible(false)
              setPdVisible(false)
              setRhVisible(true)
            }}
            type="button"
            className={`${
              !isPtVisible & !isPdVisible & isRhVisible
                ? "bg-gray-100"
                : "bg-white"
            } relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-4 py-2 text-xs font-medium text-gray-700 
            hover:bg-gray-50 focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500 sm:text-sm`}
          >
            Rh
          </button>
        </span>
      </div>
      <div>
        <span className="relative z-0 inline-flex rounded-md shadow-sm">
          <button
            onClick={() => {
              setUSD(true)
            }}
            type="button"
            className={`${
              isUSD ? "bg-gray-100" : "bg-white"
            } relative inline-flex items-center rounded-l-md border border-gray-300 px-1 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 
            focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500 sm:px-4 sm:text-sm`}
          >
            $ / troy oz.
          </button>
          <button
            onClick={() => {
              setUSD(false)
            }}
            type="button"
            className={`${
              !isUSD ? "bg-gray-100" : "bg-white"
            } relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 px-1 py-2 text-xs font-medium text-gray-700 hover:bg-gray-50 
            focus:z-10 focus:border-orange-500 focus:outline-none focus:ring-1 focus:ring-orange-500 sm:px-4 sm:text-sm`}
          >
            ₽ / грамм
          </button>
        </span>
      </div>
    </div>
  )
}
