import React from "react"

export default function Table({ data, isUSD }) {
  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Дата
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    $ / руб.
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    PT <br />
                    {isUSD ? "$ за troy oz." : "₽ / грамм"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    PD <br />
                    {isUSD ? "$ за troy oz." : "₽ / грамм"}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    RH <br />
                    {isUSD ? "$ за troy oz." : "₽ / грамм"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {[...data].reverse().map((record, recordIdx) =>
                  recordIdx < 10 ? (
                    <tr
                      key={`fix-table-${recordIdx}`}
                      className={
                        recordIdx % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }
                    >
                      <td className="whitespace-nowrap px-6 py-3 text-center text-sm font-medium text-gray-900">
                        {record?.date}
                      </td>
                      <td className="whitespace-nowrap px-6 py-3 text-center text-sm text-gray-500">
                        {record?.USDRUB}
                      </td>
                      <td className="whitespace-nowrap px-6 py-3 text-center text-sm text-gray-500">
                        {isUSD ? record?.ptValue : record?.ptRUB}
                      </td>
                      <td className="whitespace-nowrap px-6 py-3 text-center text-sm text-gray-500">
                        {isUSD ? record?.plValue : record?.plRUB}
                      </td>
                      <td className="whitespace-nowrap px-6 py-3 text-center text-sm text-gray-500">
                        {isUSD ? record?.rhValue : record?.rhRUB}
                      </td>
                    </tr>
                  ) : null
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
