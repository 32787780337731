import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Rates from "../components/Rates"

const RatesPage = () => (
  <Layout>
    {
      // eslint-disable-next-line
      <SEO
        title="Котировки"
        description="Актуальные цены на драгоценные металлы"
      />
    }
    <div className="pt-20 lg:pt-16">
      <section
        id="rates"
        aria-labelledby="rates-heading"
        className="mx-auto max-w-xl py-6 px-0 sm:py-24 md:px-12 lg:max-w-7xl"
      >
        <h1
          id="rates-heading"
          className="text-center text-4xl font-extrabold md:text-5xl"
        >
          Котировки
        </h1>
        <h2 className="py-2 text-center text-xl font-light md:text-2xl">
          Актуальные цены на драгоценные металлы
        </h2>
        <Rates />
      </section>
    </div>
  </Layout>
)

export default RatesPage
