import React, { useState } from "react"
import { useQuery } from "react-query"

import ButtonGroup from "./ButtonGroup"
import Chart from "./Chart"
import Table from "./Table"
import ChartUSD from "./ChartUSD"
import { API_URL } from "../../services/constants"

export default function Rates() {
  const { isLoading, error, data, isFetching } = useQuery(
    "ratesHistoryData",
    () => fetch(`${API_URL}/rates`).then(res => res.json())
  )

  const [isPtVisible, setPtVisible] = useState(true)
  const [isPdVisible, setPdVisible] = useState(false)
  const [isRhVisible, setRhVisible] = useState(false)

  const [isUSD, setUSD] = useState(true)

  if (isLoading || isFetching)
    return <div className="h-96 pt-4">Загрузка данных...</div>
  if (error) return <div className="h-96 pt-4">Ошибка загрузки данных</div>

  return (
    <div>
      <ButtonGroup
        isPtVisible={isPtVisible}
        isPdVisible={isPdVisible}
        isRhVisible={isRhVisible}
        setPtVisible={setPtVisible}
        setPdVisible={setPdVisible}
        setRhVisible={setRhVisible}
        isUSD={isUSD}
        setUSD={setUSD}
      />
      <Chart
        data={data}
        isPtVisible={isPtVisible}
        isPdVisible={isPdVisible}
        isRhVisible={isRhVisible}
        isUSD={isUSD}
      />

      <h3 className="py-4 text-center text-xl font-light md:text-2xl">
        Официальный курс доллара, устанавливаемый ежедневно ЦБ
      </h3>
      <ChartUSD
        data={data}
        isPtVisible={isPtVisible}
        isPdVisible={isPdVisible}
        isRhVisible={isRhVisible}
        isUSD={isUSD}
      />

      <h3 className="py-4 text-center text-xl font-light md:text-2xl">
        Фиксинги основных показателей (Pt, Pd, Rh)
      </h3>

      <Table data={data} isUSD={isUSD} />
    </div>
  )
}
