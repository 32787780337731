import React from "react"
import {
  AreaChart,
  Area,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  XAxis,
} from "recharts"

export default function ChartUSD({ data }) {
  return (
    <div className="h-56 pt-4 sm:h-96">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 10,
            bottom: 10,
          }}
        >
          <defs>
            <linearGradient id="colorUSD" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FFFF00" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#FFFF00" stopOpacity={0} />
            </linearGradient>
          </defs>

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date">
            {/* <Label value="Дата" offset={0} position="insideBottom" /> */}
          </XAxis>

          <Tooltip />
          <Legend verticalAlign="top" height={36} />

          <Area
            type="monotone"
            dataKey="USDRUB"
            stroke="#808080"
            fillOpacity={1}
            fill="url(#colorUSD)"
            name="руб за $"
          />
          <YAxis domain={[40, 100]} />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
