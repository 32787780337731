import React from "react"
import {
  AreaChart,
  Area,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  XAxis,
} from "recharts"

export default function Chart({
  data,
  isPtVisible,
  isPdVisible,
  isRhVisible,
  isUSD,
}) {
  return (
    <div className="h-56 pt-4 sm:h-96">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 10,
            bottom: 10,
          }}
        >
          <defs>
            <linearGradient id="colorPt" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorPl" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorRh" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
            </linearGradient>
          </defs>

          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date">
            {/* <Label value="Дата" offset={0} position="insideBottom" /> */}
          </XAxis>
          {/* <YAxis
            label={{ value: "Цена", angle: -90, position: "insideLeft" }}
          /> */}

          <Tooltip />
          <Legend verticalAlign="top" height={36} />
          {isPtVisible ? (
            <>
              <Area
                type="monotone"
                dataKey={`${isUSD ? "ptValue" : "ptRUB"}`}
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorPt)"
                name="Платина"
              />
              <YAxis domain={[800, 1200]} />
            </>
          ) : null}
          {isPdVisible ? (
            <>
              <Area
                type="monotone"
                dataKey={`${isUSD ? "plValue" : "plRUB"}`}
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorPl)"
                name="Палладий"
              />
              <YAxis domain={[600, 2700]} />
            </>
          ) : null}
          {isRhVisible ? (
            <>
              <Area
                type="monotone"
                dataKey={`${isUSD ? "rhValue" : "rhRUB"}`}
                stroke="#ffc658"
                fillOpacity={1}
                fill="url(#colorRh)"
                name="Родий"
              />
              <YAxis domain={[1500, 19000]} />
            </>
          ) : null}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  )
}
